<template>
  <div class="footer">
    <el-row type="flex" justify="start">
      <el-col v-if="footStyle === 'default'">
        <el-row>
          <div class="about">
            <span class="font-s14" @click.stop="setDialog('关于我们', '关于我们')">关于我们</span>
            <el-divider direction="vertical"></el-divider>
            <span class="font-s14" @click.stop="setDialog('联系我们', '联系我们')">联系我们</span>
            <el-divider direction="vertical"></el-divider>
            <span class="font-s14" @click.stop="setDialog('隐私声明', '隐私声明')">隐私声明</span>
          </div>
        </el-row>
        <el-row class="about">
          <div class="font-s14 gray click" @click="copyWrite">
            ©2010&nbsp;央视市场研究&nbsp;&nbsp;版权所有&nbsp;&nbsp;京ICP备05043322号-1
          </div>
        </el-row>
      </el-col>
      <el-col>
        <div class="qrcode">
          <div>
            <img class="qr-img" :src="require('../assets/images/qrcode/android.jpg')" />
            <span>安卓手机下载</span>
          </div>
          <div>
            <img class="qr-img" :src="require('../assets/images/qrcode/ios.jpg')" />
            <span>苹果手机下载</span>
          </div>
          <div>
            <img class="qr-img" :src="require('../assets/images/qrcode/weixin.png')" />
            <span>微信公众号</span>
          </div>
        </div>
        <div class="font-s14 foot1 gray" v-if="footStyle === 1" @click="copyWrite">
          ©2010&nbsp;央视市场研究&nbsp;&nbsp;版权所有&nbsp;&nbsp;京ICP备05043322号-1
        </div>
      </el-col>
    </el-row>
    <el-dialog :title="dialogTitle" :visible.sync="showDialog">
      <div :class="{ container: dialogTitle === '隐私声明' }">
        <div v-if="dialogTitle === '关于我们'">
          <img class="about-img" :src="require('../assets/images/home/house.png')" />
          <div>
            央视市场研究股份有限公司（CTR）是洞察中国市场的专业品牌，是中国国际电视总公司和全球知名市场研究品牌KANTAR集团合资的股份制企业。
          </div>
        </div>
        <div v-if="dialogTitle === '联系我们'">
          <div>公司地址：北京市西城区德外大街5号</div>
          <br />
          <div>座机拨打：8008100179（密码9000免费）</div>
          <br />
          <div>手机拨打：4001100179 （收取市话费）</div>
        </div>
        <div v-if="dialogTitle === '隐私声明'">
          <iframe src="https://appybj.ctsp.com.cn:8080/static/h5/yinsi_new.html" frameborder="0" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    footStyle: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      showDialog: false,
      dialogTitle: '',
      dialogContent: '',
    }
  },
  methods: {
    setDialog(title, content) {
      this.dialogTitle = title
      this.dialogContent = content
      this.showDialog = true
    },
    copyWrite() {
      location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
    },
  },
}
</script>
<style lang="less" scoped>
.footer {
  background-color: #ffffff;
  padding: 2px 10%;
  display: flex;
  justify-content: center;
  .about {
    height: 38px;
    line-height: 38px;
    width: 420px;
    background-color: #f4f4f4;
    margin: 5px auto;
    text-align: center;
    margin-right: 50px;
    span {
      cursor: pointer;
    }
  }
  .gray {
    color: #666;
  }
}
.container {
  height: 500px;
  iframe {
    width: 100%;
    height: 500px;
  }
}
.qrcode {
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2px;
  width: 450px;
  margin-left: 80px;
  .qr-img {
    width: 70px;
    height: 70px;
    display: block;
  }
  span {
    margin-top: 2px;
    display: block;
    text-align: center;
    font-size: 12px;
  }
}
.foot1 {
  height: 26px;
  line-height: 28px;
  background-color: #f4f4f4;
  padding-left: 10px;
  margin-bottom: 2px;
  text-align: center;
  color: #666;
  cursor: pointer;
}
.click {
  cursor: pointer;
}
.about-img {
  width: 100%;
}
</style>
