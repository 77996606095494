<template>
  <div class="container">
    <!-- 标题 -->
    <div class="title">礼品兑换</div>
    <div class="split" />
    <!-- 内容 -->
    <div class="content">
      <!-- 左侧导航 -->
      <div class="nav">
        <div
          :class="{ active: $route.path !== '/redeem-gift/handbook' }"
          @click="$router.push('/redeem-gift/gift-list')"
        >
          礼品列表
        </div>
        <div
          :class="{ active: $route.path === '/redeem-gift/handbook' }"
          @click="$router.push('/redeem-gift/handbook')"
        >
          兑换须知
        </div>
      </div>
      <div class="view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'redeem-gift',
  watch: {
    '$route.path': {
      handler() {
        if (this.$route.path === '/redeem-gift') {
          this.$router.replace('/redeem-gift/gift-list')
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: auto;
  max-width: 1198px;
  margin: 0 auto 30px;
  padding: 0 20px;
  box-sizing: border-box;
  .title {
    margin-top: 35px;
    width: 207px;
    min-width: 207px;
    height: 40px;
    background-color: #ec7310;
    line-height: 40px;
    text-align: center;
    color: #fff;
  }
  .split {
    max-width: 100%;
    width: 100vw;
    height: 3px;
    background-color: #ec7310;
    position: absolute;
    left: 0;
  }
  .content {
    display: flex;
    align-items: stretch;
    min-height: 90%;
    .nav {
      width: 207px;
      min-width: 207px;
      height: auto;
      background-color: #f6f6f6;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      padding-top: 19px;
      div {
        height: 38px;
        line-height: 38px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
      }
      .active {
        border-left: 3px solid #ec7310;
        color: #ec7310;
        background-image: linear-gradient(to right, #fff2e7, #f6f6f6);
      }
    }
    .view {
      flex: 1;
      height: auto;
      display: flex;
      align-items: stretch;
    }
  }
}
</style>
