<template>
  <div class="container">
    <!-- 确认订单标题 -->
    <div class="info">
      <div class="title">订单确认</div>
      <span>填写并核对订单信息</span>
    </div>
    <!-- 地址 -->
    <div class="panel" v-loading="addressLoading">
      <div class="panel-title">收货信息</div>
      <!-- 无收货地址 -->
      <div class="no-address" v-if="!addressList.length">
        <div>您目前还未设置收货地址，请您添加！</div>
        <el-button round plain type="primary" class="add" icon="el-icon-plus" @click="setAddress">新增地址</el-button>
      </div>
      <!-- 有收货地址 -->
      <div class="address" v-else>
        <div class="more"><span @click="setAddress">更多地址</span></div>
        <div class="address-list">
          <div class="address-list-atom">
            <!-- <el-radio label="1" border value="1">{{ selectAddress.receiver }}</el-radio> -->
            <el-select
              v-model="selectAddressId"
              placeholder="请选择"
              style="width: 120px; margin-right: 20px"
              @change="getGift"
            >
              <el-option v-for="a in addressList" :key="a.addr_id" :label="a.receiver" :value="a.addr_id">
                <span
                  v-html="
                    `${a.receiver}  ${a.province} ${a.city} ${a.county} ${a.town} ${a.addr} ${a.tel.replace(
                      /^(\d{3})\d{4}(\d{4})$/,
                      '$1****$2'
                    )}`
                  "
                />
                <span style="float: right; color: #da773d; font-size: 13px; margin-left: 10px" v-if="a.is_default"
                  >默认</span
                >
              </el-option>
            </el-select>
            <div class="detail">
              <div>{{ selectAddress.province }}</div>
              <div>{{ selectAddress.city }}</div>
              <div>{{ selectAddress.county }}</div>
              <div>{{ selectAddress.town }}</div>
              <div>{{ selectAddress.addr }}</div>
              <div>{{ selectAddress.tel.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') }}</div>
              <div style="color: #da773d" v-if="selectAddress.is_default">默认</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="panel">
      <div class="panel-title">订单信息</div>
      <div class="order" v-if="giftInfo" v-loading="giftLoading">
        <!-- 物品图片 -->
        <div class="good">
          <img :src="giftInfo.images[0].url" />
          <div class="mask" v-if="giftInfo.gift_state !== '有货'"><div>无货</div></div>
          <div>{{ giftInfo.name }}</div>
        </div>
        <div><span>礼品编号</span> {{ giftInfo.code }}</div>
        <div><span>点值</span> {{ giftInfo.point }}</div>
        <div><span>供应商</span> 京东</div>
        <div><span>数量</span>1</div>
      </div>
    </div>
    <!-- 确认订单的提示信息 -->
    <div class="info"><span>订单提交无法修改、取消、变更、退订，请确认无误后再提交</span></div>
    <!-- 最下角的确认近一步操作 -->
    <div class="firm panel" v-if="giftInfo">
      <div>已选择 <span>1</span> 件商品</div>
      <div class="point">
        总点值：<span class="point-num">{{ giftInfo.point }}</span>
      </div>
      <el-button type="primary" @click="submit" :disabled="giftInfo.gift_state !== '有货'">提交订单</el-button>
    </div>
    <!-- 模态框 -->
    <!-- 验证码 -->
    <el-dialog :visible.sync="dialogCaptchaVisible" width="495px" title="手机验证">
      <div class="modal">
        <el-form class="wrap-form">
          <el-form-item class="wrap-form-item">
            <el-row type="flex" justify="start">
              <el-input disabled :value="hideTel" type="text" placeholder="请输入手机号" style="width: 300px" />
              <div class="button-gary send-button" @click="getCaptcha" v-if="!timer">{{ sendCaptchaText }}</div>
              <div class="button send-button disabled" v-else>{{ count }}秒后重新获取</div>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-input v-model="captcha" type="text" placeholder="请输入验证码" />
          </el-form-item>
          <el-form-item>
            <div class="button-radius ok-button font-s20" @click="submitCaptcha">确定</div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 确认框 -->
    <el-dialog :visible.sync="dialogConfirmVisible" width="350px" title="提示">
      <div class="modalConfirm">
        <div class="content">订单提交无法修改、取消、变更、退订，请确认无误后再提交。</div>
        <div class="tools">
          <el-button round type="default" class="tools-left" @click="dialogConfirmVisible = false">再想想</el-button>
          <el-button
            round
            type="primary"
            @click="
              () => {
                dialogConfirmVisible = false
                confirm = true
                submit()
              }
            "
            >已确认</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { service } from '@/utils/axios'
import store from '@/store'
import { mapGetters } from 'vuex'
export default {
  name: 'firm-order',
  data() {
    return {
      selectAddressId: null,
      dialogCaptchaVisible: false,
      dialogConfirmVisible: false,
      addressList: [],
      addressLoading: false,
      giftLoading: false,
      giftInfo: null,
      giftId: null,
      confirm: false,
      tel: '',
      captcha: '',
      // 计时器
      timer: null,
      // 剩余时间
      count: 0,
      // 默认时间
      defaultCount: 60,
      sendCaptchaText: '发送验证码',
    }
  },
  computed: {
    ...mapGetters(['user']),
    selectAddress: function () {
      if (!this.selectAddressId) {
        return {}
      }
      return this.addressList.find(addr => addr.addr_id === this.selectAddressId)
    },
    hideTel: function () {
      return this.tel.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    },
  },
  methods: {
    // 跳转到设置地址
    setAddress() {
      // 跳走之前记录路由
      store.commit('user/SET_NEXT', this.$route.fullPath)
      this.$router.push('/address-manage')
    },
    // 获取地址
    async getAddress() {
      this.addressLoading = true
      // 获取收货地址
      const res = await service.post('/app/get_addr_list')
      this.addressLoading = false
      if (res.data.ret_code === 0 && res.data.data.addr_list && res.data.data.addr_list.length) {
        this.addressList = res.data.data.addr_list
        this.selectAddressId = this.addressList.find(addr => addr.is_default === true)?.addr_id
        if (!this.selectAddressId) {
          this.selectAddressId = this.addressList[0].addr_id
        }
      }
    },
    // 回显礼品
    async getGift() {
      this.giftLoading = true
      const res = await service.post('/app/get_gifts', { id: this.giftId })
      if (res.data.ret_code === 0) {
        if (res.data.data.gift_list && res.data.data.gift_list.length) {
          this.giftInfo = res.data.data.gift_list[0]
        }
      }
      if (!this.selectAddressId) {
        this.giftLoading = false
        return
      }
      const gift_res = await service.post('/app/get_gifts', {
        addr_id: this.selectAddressId,
        gift_state: [{ id: this.giftId, count: 1 }],
      })
      this.giftLoading = false

      if (gift_res.data.ret_code === 0) {
        this.giftInfo.gift_state = gift_res.data.data.gift_list[0].gift_state
      }
    },
    // 提交
    async submit() {
      if (!this.addressList.length) {
        this.$message.error('请先添加收货地址')
        return
      }
      if (!this.selectAddress) {
        this.$message.error('请设置收货地址')
        return
      }
      if (!this.confirm) {
        this.dialogConfirmVisible = true
        return
      }
      const payload = {
        gift_id: this.giftId,
        gift_count: 1,
        addr_id: this.selectAddressId,
      }
      const res = await service.post('/app/submit_order_sw', payload)
      if (res.data.ret_code === 0) {
        if (res.data.data.order_id) {
          this.$router.push({ path: '/order-success', query: { id: res.data.data.id } })
        }
      } else {
        if (!res.data.data.is_verify && res.data.ret_code === -2) {
          this.dialogCaptchaVisible = true
          return
        }
        this.$router.push('/order-failed')
      }
    },
    // 获取验证码
    async getCaptcha() {
      if (this.timer) {
        return
      }
      if (!this.tel) {
        this.$message.error('请输入手机号')
        return
      }
      if (!/^1[3-9]\d{9}$/.test(this.tel)) {
        this.$message.error('手机号格式错误')
        return
      }
      const res = await service.post('/app/send_verify_code', { tel: this.tel })
      if (res.data.ret_code === 0) {
        this.count = this.defaultCount
        this.timer = window.setInterval(() => {
          if (this.count > 0 && this.count <= this.defaultCount) {
            this.count--
          } else {
            this.sendCaptchaText = '重新发送'
            this.timer = null
            window.clearInterval(this.timer)
          }
        }, 1000)
      }
    },
    async submitCaptcha() {
      if (!this.captcha) {
        this.$message.error('请输入验证码')
        return
      }
      if (!/^\d{6}$/.test(this.captcha)) {
        this.$message.error('验证码格式错误')
        return
      }
      const res = await service.post('/app/verify_verify_code', { tel: this.tel, verify: this.captcha })
      if (res.data.ret_code === 0) {
        this.dialogCaptchaVisible = false
        this.submit()
      }
    },
    async init() {
      await this.getAddress()
      this.getGift()
    },
  },
  created() {
    this.giftId = this.$route.query.id
    this.init()
    this.tel = this.user.tel
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  max-width: 1198px;
  margin: 0 auto 30px;
  padding: 0 20px;
  box-sizing: border-box;
  .info {
    margin-top: 18px;
    padding-left: 25px;
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    background: #f8f8f8;
    color: #333;
    font-size: 20px;
    line-height: 44px;
    .title {
      display: inline;
      margin-right: 14px;
    }
    span {
      font-size: 14px;
      color: #999;
    }
  }
  .panel {
    margin-top: 18px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1px;
    border: 1px solid #dddddd;
    background: #fff;
    &-title {
      font-size: 16px;
      color: #333;
      margin: 16px 0 0 25px;
    }
    .no-address {
      display: flex;
      flex-flow: column;
      align-items: center;
      div {
        color: #999;
        font-size: 14px;
        text-align: center;
      }
      .add {
        width: 157px;
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }
    .address {
      .more {
        display: flex;
        justify-content: flex-end;
        margin-right: 25px;
        font-size: 14px;
        color: #999;
        span {
          cursor: pointer;
        }
      }
      &-list {
        margin-left: 24px;
        margin-bottom: 30px;
        &-atom {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          color: #666;
          font-size: 14px;
          .detail {
            display: flex;
            div {
              margin-right: 20px;
            }
          }
        }
      }
    }
    .order {
      margin: 19px 25px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      .good {
        display: flex;
        position: relative;
        img {
          width: 78px;
          height: 78px;
          background: white;
          margin-right: 5px;
        }
        .mask {
          width: 78px;
          height: 78px;
          opacity: 0.8;
          position: absolute;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            width: 60%;
            height: 60%;
            border-radius: 50%;
            background: #b1b1b1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            opacity: 1;
            font-size: 13px;
          }
        }
      }
      span {
        color: #999;
      }
    }
  }
  .firm {
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    div {
      margin-right: 26px;
    }
    span {
      color: #ec7717;
    }
    .point {
      display: flex;
    }
    .point-num {
      font-size: 18px;
    }
    ::v-deep .el-button {
      border-radius: 0px;
      height: 48px;
      border: 0;
    }
  }
  .modal {
    margin: 0 auto;
    &-form {
      width: 500px;
      margin: 0 auto;
      padding-top: 60px;
    }
    .send-button {
      width: 145px;
      margin-left: 10px;
      cursor: pointer;
    }
    .disabled {
      text-align: center;
      cursor: default;
      background: #fff;
      border: 1px solid #999;
      color: #999;
    }
    .ok-button {
      width: 455px;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
  .modalConfirm {
    .content {
      padding: 0 20px 20px;
    }
    .tools {
      display: flex;
      justify-content: center;
      &-left {
        margin-right: 10px;
      }
    }
  }
}

::v-deep .el-input__inner {
  border-color: #ec7310;
  font-weight: bolder;
}

::v-deep .el-select__caret {
  appearance: none;
  background: center/ 16px no-repeat url(../../../assets/images/双箭头.png);
}

::v-deep .el-icon-arrow-up:before {
  content: '';
}
</style>
