<template>
  <div class="container">
    <div class="my-point-panel">
      <div class="my-point-panel-left">
        <div class="circle">{{ point }}</div>
        <span class="info">我的点值</span>
      </div>
      <div class="my-point-panel-right">
        <span>共搜索 {{ giftList.length }} 件礼品</span>
      </div>
    </div>
    <div class="tools">
      <el-button type="defalut" plain @click="changeOrderBy"
        >点值排序<i :class="{ 'el-icon-caret-top': asc, 'el-icon--right': true, 'el-icon-caret-bottom': !asc }"></i
      ></el-button>
    </div>
    <div v-if="giftList.length" v-loading="loading">
      <!-- 礼品 -->
      <div v-for="(gift, index) in showList" :key="gift.id">
        <div class="gift">
          <img v-if="gift.images.length" :src="gift.images[0].url" @click="toDetail(gift.id)" />
          <img v-else src="" @click="toDetail(gift.id)" />
          <div class="gift-card">
            <div class="gift-card-left">
              <div class="title" @click="toDetail(gift.id)">{{ gift.name }}</div>
              <div class="point">
                <span>点值</span> <b>{{ gift.point }}</b>
              </div>
              <div class="stock">
                库存 <span>{{ gift.count > 0 ? '有货' : '无货' }}</span>
              </div>
              <div class="no">
                礼品编号 <span>{{ gift.code }}</span>
              </div>
            </div>
            <div class="gift-card-right">
              <el-button
                round
                @click="toDetail(gift.id)"
                :disabled="gift.count <= 0"
                :type="gift.count <= 0 ? 'default' : 'primary'"
                >礼品详情</el-button
              >
            </div>
          </div>
        </div>
        <div class="divider" v-if="index !== giftList.length - 1" />
      </div>
      <!-- 翻页 -->
      <div class="page" v-if="giftList.length > pageSize">
        <el-pagination
          background
          layout="prev, pager, next"
          :data-list="giftList"
          :total="giftList.length"
          :current-page="currentPage"
          :page-size="pageSize"
          @current-change="p => (currentPage = p)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { service } from '@/utils/axios'
import store from '@/store'
export default {
  name: 'gift-list',
  data() {
    return {
      asc: true,
      giftList: [],
      loading: true,
      currentPage: 1,
      pageSize: 5,
      point: null,
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({ path: '/redeem-gift/gift-detail', query: { id } })
    },
    async init() {
      const res = await service.post('/app/get_jd_gifts', {
        // class_id: 1,
        order_by: this.asc ? 'gift_t.point' : 'gift_t.point.desc()',
      })
      if (res.data.ret_code === 0) {
        this.loading = false
        this.giftList = res.data.data.gift_list
      }
      const resPoint = await service.post('app/get_point_log')
      this.point = resPoint.data.data.totle_point
      store.commit('user/SET_POINT', this.point)
    },
    changeOrderBy() {
      this.asc = !this.asc
      this.currentPage = 1
      this.init()
    },
  },
  computed: {
    ...mapGetters(['user']),
    showList() {
      return this.giftList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  created() {
    this.init()
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 32px 0 0 29px;
  box-sizing: border-box;
  .my-point-panel {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
    padding: 0 38px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      .circle {
        min-width: 58px;
        height: 58px;
        background: #ec7310;
        border-radius: 29px;
        line-height: 58px;
        text-align: center;
        font-size: 20px;
        color: #fff;
      }
      .info {
        margin-left: 20px;
        color: #333;
        font-size: 14px;
        line-height: 58px;
      }
    }
    &-right {
      color: #999;
      font-size: 14px;
    }
  }
  .tools {
    float: right;
  }
  .gift {
    width: 100%;
    // background: yellow;
    background-clip: content-box;
    padding: 18px 0;
    display: flex;
    img {
      width: 100px;
      height: 100px;
      background: #fff;
      cursor: pointer;
    }
    &-card {
      display: flex;
      margin-left: 28px;
      width: 100%;
      justify-content: space-between;
      font-size: 14px;
      color: #999;
      &-left {
        .title {
          color: #333;
          font-size: 16px;
          cursor: pointer;
        }
        div {
          margin-bottom: 5px;
        }
        .point {
          display: flex;
          align-items: center;
          span {
            display: block;
            margin-right: 10px;
          }
          b {
            font-size: 16px;
            display: block;
            color: #ec7310;
            font-weight: normal;
          }
        }
        .stock {
          span {
            margin-left: 10px;
            color: #333;
          }
        }
        .no {
          span {
            margin-left: 10px;
            color: #333;
          }
        }
      }
      &-right {
        margin-top: 10px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #eee;
  }
  .page {
    margin-top: 20px;
    float: right;
  }
}
::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 15px;
}
</style>
