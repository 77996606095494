<template>
  <div class="container">
    <el-collapse>
      <el-collapse-item title="点值相关" name="1">
        <div>
          <h3>点值什么时候到账</h3>
          <p>
            连续调查合作项目每月5号添加（遇节假日延后），临时性个案项目根据项目完成时间不同，会在项目合作开始时，由工作人员明确告知大概加点时间。
          </p>
        </div>
        <div>
          <h3>退出合作后点值的使用有效期</h3>
          <p>
            登录【样本家】APP礼品平台的权限是自工作人员告知您退出日起31天，未在期限内消化剩余点值视为放弃，31天后登录权限关闭，点值自动清零。
          </p>
        </div>
        <div>
          <h3>兑换礼品订单失败，礼品点值去向</h3>
          <p>
            订单状态显示交易失败、取消、异常的订单，点值自动退还到账户，如对剩余点值有异议，可累加获得点值明细及所有兑换过的订单总点值进行核查。
          </p>
        </div>
      </el-collapse-item>
      <el-collapse-item title="礼品上新" name="2">
        <div>
          <h3>为什么心仪的礼品总是显示无货</h3>
          <p>
            【样本家】APP礼品平台系统与京东网系统对接，商品信息直接调用京东网，库存以京东网站显示所在地区为准。当【样本家】APP显示无库存时，建议兑换京东E卡自行购买类似心仪礼品。
          </p>
        </div>
        <div>
          <h3>礼品多久上新一次</h3>
          <p>
            【样本家】APP礼品平台会根据库存和商品换代情况进行更新，更新礼品显示在【礼品上新啦】专栏。有心仪礼品，请尽早兑换，以免错过。
          </p>
        </div>
        <div>
          <h3>礼品种类不能满足我需求</h3>
          <p>
            提供礼品种类有限，如没有您心仪礼品，建议兑换京东电子卡类或其它虚拟礼品；样本家APP礼品种类更丰富，欢迎下载使用。
          </p>
        </div>
      </el-collapse-item>
      <el-collapse-item title="关于礼品实物订单发货" name="3">
        <div>
          <h3>订单提交后什么时候发货</h3>
          <p>
            京东网、当当网代发礼品正常24小时发货，最晚不会超过72小时，如遇节假日略有延迟。聚恒公司（礼品编号JH）代发礼品，每周一上午发货，预计3-4天送达。
          </p>
        </div>
      </el-collapse-item>
      <el-collapse-item title="哪里可以查看物流" name="4">
        <p>
          物流信息显示在【订单】页面，京东网、当当网代发礼品提交订单后即可查看物流情况，可每天关注物流动态，及时收货。聚恒公司（礼品编号JH）礼品订单的快递信息，每周五更新，需自行按快递单号查询物流情况。
        </p>
      </el-collapse-item>
      <el-collapse-item title="关于实物礼品收货注意事宜" name="5">
        <div>
          <h3>关于签收</h3>
          <p>
            收货时请务必您本人签收，并当面开箱验货，确保商品完好无损，如商品破损请直接拒收。签收后（包括传达室、保安、亲友代签），均代表您已确认商品完好。
          </p>
        </div>
        <div>
          <h3>关于退换</h3>
          <p>
            礼品属于点值兑换，不支持随意退换货，请您理解！如拒收、重复兑换、收货信息及手机号错误、不喜欢等非质量问题拒收的，需要由您承担来回运费500点值，运费在货物退回商家后从订单中扣除。
          </p>
        </div>
        <div>
          <h3>物品色差</h3>
          <p>受手机或电脑显示器分辨率以及拍照光线影响，床上用品及织物类礼品可能存在一定色差，请以实物为准。</p>
        </div>
      </el-collapse-item>
      <el-collapse-item title="关于售后" name="6">
        <div>
          <h3>收到礼品出现质量问题如何处理</h3>
          <p>
            收货后产品非人为造成，属产品本身质量问题自收货7天内提出申请，通过【收货反馈】提供商品破损照片，商家核实无误后同产品换货；超过7天出现质量问题，请直接联系品牌售后。
          </p>
        </div>
        <div>
          <h3>兑换实物礼品时总提示失败</h3>
          <p>
            礼品平台系统与京东网、当当网对接，发货地址信息直接读取京东网信息，当兑换礼品提示订单失败时，基本原因为地址信息未匹配，请您删除礼品网站地址管理中的地址信息重新填写后再次兑换提交即可。
          </p>
        </div>
      </el-collapse-item>
      <!-- <el-collapse-item title="关于话费充值" name="7">
        <div>
          <h3>关于手机充值话费</h3>
          <p>
            1.手机充值话费三网合一，请结合持有点值选择确认需充值面额兑换。<br />
            2.在线话费充值正常情况下5分钟内到帐，最长不超过10分钟，特殊情况延迟几分钟到帐。<br />
            3.月初月末供应商系统维护，充值容易失败，建议尽量月中充值。<br />
            4.如显示已充值成功，且长时间（至少10分钟）未到账，请您进入手机号所属运营商网站或APP，查询充值记录，并进行截图。登录样本家进入【订单】通过【收货反馈】上传图片给我们，等待处理即可。
          </p>
        </div>
      </el-collapse-item>
      <el-collapse-item title="电子类礼品绑定流程" name="8">
        <iframe src="https://app2.ctsp.com.cn:8080/static/h5/08-1.html" frameborder="0" />
      </el-collapse-item> -->
      <el-collapse-item title="特别提醒" name="9">
        <div>
          <h3>特别提醒</h3>
          <p>
            样本家APP、网站是用于央视市场研究调查合作用户兑换礼品、参与调查平台，通过平台兑换的礼品不收取任何费用，凡是打着我司名誉向您收取费用，均为诈骗行为，请勿上当受骗。
          </p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'handbook',
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: auto;
  padding: 32px 0 0 29px;
  box-sizing: border-box;
  h3 {
    color: #ec7310;
    margin-top: 5px;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
  p {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
  iframe {
    width: 100%;
    height: 600px;
  }
}
::v-deep .el-collapse-item__header {
  font-size: 14px;
}
::v-deep .el-collapse-item__header::before {
  content: '';
  display: block;
  width: 38px;
  height: 38px;
  margin-right: 13px;
  background: url(../../../assets/images/book.png) no-repeat center center;
}
::v-deep .el-collapse-item__wrap {
  background-color: #f0f2f5 !important;
}
</style>
