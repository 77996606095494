import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home.vue'
import Store from '@/store'
import RedeemGift from '@/views/redeem-gift/RedeemGift.vue'
import GiftList from '@/views/redeem-gift/gift/List.vue'
import GiftDetail from '@/views/redeem-gift/gift/Detail.vue'
import Handbook from '@/views/redeem-gift/handbook/Handbook.vue'
import FirmOrder from '@/views/redeem-gift/gift/FirmOrder.vue'
import OrderFailed from '@/views/redeem-gift/result/Failed.vue'
import OrderSuccess from '@/views/redeem-gift/result/Success.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    meta: {
      title: ' ',
    },
  },
  {
    path: '/set-password',
    component: () => import('@/views/login/set-password'),
    meta: {
      title: '重置密码',
      footer: 1,
    },
  },
  {
    path: '/first-set-password',
    component: () => import('@/views/login/first-set-password'),
    meta: {
      title: '设置密码',
      footer: 1,
    },
  },
  {
    path: '/my',
    component: () => import('@/views/my/index'),
    children: [
      {
        path: 'cooperate-info',
        name: 'cooperate-info',
        component: () => import('@/views/my/cooperate-info'),
      },
      {
        path: 'point-detail',
        name: 'point-detail',
        component: () => import('@/views/my/point-detail'),
      },
      {
        path: 'my-order',
        name: 'my-order',
        component: () => import('@/views/my/my-order'),
      },
      {
        path: 'order-detail',
        name: 'order-detail',
        component: () => import('@/views/my/order-detail'),
      },
      {
        path: 'address-manage',
        name: 'address-manage',
        component: () => import('@/views/my/address-manage'),
      },
    ],
  },
  {
    path: '/questionnaire-list',
    component: () => import('@/views/questionnaire/List.vue'),
  },
  {
    path: '/redeem-gift',
    component: RedeemGift,
    children: [
      {
        path: 'gift-list',
        component: GiftList,
      },
      {
        path: 'gift-detail',
        component: GiftDetail,
      },
      {
        path: 'handbook',
        component: Handbook,
      },
    ],
  },
  {
    path: '/firm-order',
    component: FirmOrder,
  },
  {
    path: '/address-manage',
    component: () => import('@/views/my/address-manage'),
  },
  {
    path: '/order-detail',
    component: () => import('@/views/my/order-detail'),
  },
  {
    path: '/order-success',
    component: OrderSuccess,
  },
  {
    path: '/order-failed',
    component: OrderFailed,
  },
]

const router = new VueRouter({
  routes,
})
const whiteList = ['/login', '/set-password']
router.beforeEach(async (to, _, next) => {
  if (!Store.state.user.name && !whiteList.find(r => r === to.path)) {
    router.push('/login')
  }
  next()
})

export default router
