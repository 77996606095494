<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // 背景颜色不是白色的路由
      bgcNotWhiteRoutes: ['/', '/login'],
      navList: [
        { name: '首页', path: '/', needLogin: false },
        // 已经在my里边加了跳转逻辑
        { name: '我的', path: '/my', needLogin: true },
        { name: '礼品兑换', path: '/redeem-gift', needLogin: false },
        { name: '问卷调查', path: '/questionnaire-list', needLogin: true },
      ],
      showDialog: false,
    }
  },
  methods: {
    reload() {
      this.$router.replace('/')
    },
    routerPush(path) {
      this.$router.push(path)
    },
    logout() {
      // 清空
      if (this.user.tel) {
        this.$service.get('/app/logout')
      }
      store.commit('user/RESET_STATE')
      // 刷新
      this.$router.replace('/login')
    },
    // 同意或者不同意隐私声明
    async privacy(isAccept) {
      await store.dispatch('user/acceptPrivacy', isAccept)
      this.showDialog = false

      if (!isAccept) {
        this.logout()
      }
    },
    // header导航路由
    push(nav) {
      if (!nav.needLogin) {
        this.$router.push(nav.path)
      } else {
        if (this.user.tel) {
          this.$router.push(nav.path)
        }
      }
    },
    // 下拉框路由，需要记录next
    dropdownPush(nav) {
      store.commit('user/SET_NEXT', this.$route.path)
      this.$router.push(nav)
    },
  },
  computed: {
    ...mapGetters(['user']),
    notWhite: function () {
      return this.bgcNotWhiteRoutes.find(r => r === this.$route.path)
    },
    title: function () {
      if (this.$route.meta.title) {
        return this.$route.meta.title
      } else {
        return ''
      }
    },
    footer: function () {
      return this.$route.meta.footer
    },
  },
  watch: {
    user: {
      handler(user) {
        if (user.tel && !user.info.is_accept_privacy_statement) {
          this.showDialog = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<template>
  <div :class="{ root: true, notWhite }">
    <Header class="header">
      <template #left>
        <!-- 图标 -->
        <div class="header-left">
          <a @click="reload"><img :src="require('./assets/images/logo.png')" class="logo" /></a>
          <div>{{ title }}</div>
        </div>
      </template>
      <template #right>
        <div :class="{ nav: true, fix: $route.path === '/' }" v-if="!title">
          <!-- 路由导航 -->
          <div v-for="(nav, index) in navList" :key="nav.name">
            <span
              :class="{
                'nav-atom': !nav.needLogin || user.tel,
                active:
                  ($route.path.includes(nav.path) && nav.path !== '/') ||
                  (nav.path === $route.path && nav.path === '/'),
              }"
              @click="push(nav)"
              >{{ nav.name }}</span
            >
            <!-- 分割线 -->
            <span class="nav-split" v-if="index < navList.length - 1">·</span>
          </div>
          <!-- 下拉按钮 -->
          <el-dropdown class="nav-user">
            <el-button type="primary" size="small" class="nav-user-content">
              <i class="el-icon-user-solid nav-user-icon" />
              <div class="nav-user-content-txt">
                {{ user.tel.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') || user.name }}
              </div>
              <i class="el-icon-arrow-down el-icon--right nav-user-arrow-icon" />
            </el-button>
            <el-dropdown-menu slot="dropdown" class="nav-dropdown">
              <el-dropdown-item v-if="user.info && user.name !== '游客'" @click.native="dropdownPush('/set-password')"
                >重置密码</el-dropdown-item
              >
              <el-dropdown-item
                v-if="user.info && !user.info.is_have_password"
                @click.native="dropdownPush('/first-set-password')"
                >设置密码</el-dropdown-item
              >
              <el-dropdown-item v-if="!user.name" @click.native="$router.push('/login')">登录</el-dropdown-item>
              <el-dropdown-item v-if="user.name" @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </Header>
    <div class="content">
      <!-- 内容 -->
      <router-view />
    </div>
    <Footer :foot-style="footer" style="border-top: 1px solid #dddddd" />
    <el-dialog
      title="隐私声明"
      :visible.sync="showDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog">
        <iframe src="https://appybj.ctsp.com.cn:8080/static/h5/yinsi_new.html" frameborder="0" />
        <div class="tools">
          <el-button round type="default" class="tools-left" @click="privacy(false)">关闭</el-button>
          <el-button round type="primary" @click="privacy(true)">同意并继续</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="less">
* {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}
.el-dropdown-menu__item {
  text-align: center;
  min-width: 160px;
}
</style>
<style lang="less" scoped>
.root {
  //background-color: #f0f2f5;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-size: 18px;
  overflow-x: hidden;
  .header {
    background-color: #fff;
    &-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      a {
        display: block;
      }
    }
  }
  .content {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
  }
  .logo {
    cursor: pointer;
  }
  .nav {
    line-height: 37px;
    text-align: center;
    display: flex;
    &-atom {
      cursor: pointer;
      font-weight: 500;
      white-space: nowrap;
      &:hover {
        color: sandybrown;
      }
    }
    .active {
      color: sandybrown;
    }
    &-split {
      margin: 0 15px;
    }
    &-user {
      margin-left: 40px;
      &-content {
        font-size: 16px;
        min-width: 202px;
        &-txt {
          display: inline-block;
          min-width: 102px;
        }
      }
      &-icon {
        margin-right: 18px;
      }
      &-arrow-icon {
        margin-left: 18px;
      }
    }
    &-dropdown {
      text-align: center;
    }
  }
  .fix {
    position: relative;
    left: 20px;
  }
}
.notWhite {
  background-color: #fff9f4;
  .header {
    background-color: #fff9f4;
  }
}
.dialog {
  height: 340px;
  .tools {
    display: flex;
    justify-content: space-around;
    &-left {
      margin-right: 30px;
    }
  }
  iframe {
    width: 100%;
    height: 300px;
  }
}
</style>
