<template>
  <div class="container">
    <div class="gift">
      <div class="top">
        <el-carousel class="carousel" height="400px">
          <el-carousel-item v-for="img in giftInfo.images" :key="img.url">
            <img class="carousel-img" :src="img.url" />
          </el-carousel-item>
        </el-carousel>
        <div class="top-card">
          <div class="title">{{ giftInfo.name }}</div>
          <div style="background-color: #f1f1f1; margin-top: 20px; padding-bottom: 8px">
            <div class="point">
              <span>礼品点值</span> <b>{{ giftInfo.point }}</b>
            </div>
            <div class="stock">
              库存 <span>{{ giftInfo.count > 0 ? '有货' : '无货' }}</span>
            </div>
            <div class="no">
              礼品编号 <span>{{ giftInfo.code }}</span>
            </div>
            <div class="no">供货商 <span>京东自营</span></div>
            <!-- <div class="no">
              描述<span>{{ giftInfo.detail }} </span>
            </div> -->
            <!-- 带换行符 -->
            <div class="no">描述<span v-html="giftInfo.detail.replace(/\n/g, '<br />')" /></div>
          </div>
          <el-button
            class="redeem"
            style="width: 100%; height: 49px"
            v-if="user.tel"
            :type="giftInfo.count <= 0 ? 'default' : 'primary'"
            :disabled="giftInfo.count <= 0"
            @click="submit"
            >立即兑换</el-button
          >
        </div>
      </div>
    </div>
    <div class="gift-content">
      <!-- <div class="title">礼品信息</div>
      <div class="info">
        {{ giftInfo.detail }}
      </div> -->
      <div class="title" v-if="giftInfo.about_send">关于发货</div>
      <div class="info" v-if="giftInfo.about_send" v-html="giftInfo.about_send.replace(/\n/g, '<br />')" />
      <div class="title" v-if="giftInfo.about_recv">关于收货</div>
      <div class="info" v-if="giftInfo.about_recv" v-html="giftInfo.about_recv.replace(/\n/g, '<br />')" />
    </div>
  </div>
</template>

<script>
import { service } from '@/utils/axios'
import { mapGetters } from 'vuex'
export default {
  name: 'gift-list',
  methods: {
    async init(id) {
      const res = await service.post('/app/get_gifts', { id })
      if (res.data.ret_code === 0) {
        if (res.data.data.gift_list && res.data.data.gift_list.length) {
          this.giftInfo = res.data.data.gift_list[0]
        }
      }
    },
    submit() {
      if (this.user.info.point < this.giftInfo.point) {
        this.$message.error('点值不足')
        return
      }
      this.$router.push({ path: '/firm-order', query: { id: this.$route.query.id } })
    },
  },
  data() {
    return {
      giftInfo: {},
    }
  },
  created() {
    const giftId = this.$route.query.id
    this.init(giftId)
  },
  computed: {
    ...mapGetters(['user']),
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 32px 0 0 29px;
  box-sizing: border-box;
  .gift {
    .top {
      display: flex;
      .carousel {
        width: 500px;
        height: 400px;
        &-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      &-card {
        flex: 1;
        margin-left: 35px;
        font-size: 14px;
        color: #999;
        .title {
          margin-top: 8px;
          color: #333;
          font-size: 20px;
          cursor: pointer;
          margin-bottom: 10px;
        }
        div {
          margin-bottom: 10px;
        }
        .point {
          height: 40px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          margin-left: 10px;
          span {
            display: block;
            margin-right: 10px;
          }
          b {
            font-size: 26px;
            display: block;
            color: #ec7310;
            font-weight: normal;
          }
        }
        .stock {
          margin-left: 10px;
          span {
            margin-left: 10px;
            color: #333;
          }
        }
        .no {
          margin-left: 10px;
          margin-right: 10px;
          span {
            margin-left: 10px;
            color: #333;
          }
        }
        .redeem {
          margin-top: 10px;
        }
      }
    }
  }
  .gift-content {
    margin-top: 40px;
    .title {
      box-sizing: border-box;
      width: 100%;
      height: 35px;
      background: white;
      font-size: 18px;
      line-height: 35px;
      padding-left: 12px;
      border-left: 4px solid #ec7310;
    }
    .info {
      margin: 20px 12px;
      font-size: 14px;
    }
  }
}
::v-deep .el-button {
  border-radius: 2px;
}
</style>
