import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from '@/App.vue'
import ElementUI from 'element-ui'
import router from '@/router'
import store from '@/store'
import { AxiosPlugin } from '@/utils/axios'
import '@/assets/css/element-ui/index.css'
import '@/assets/css/public.css'
const packageInfo = require('../package.json')

Vue.config.productionTip = false
// 暂时全量引入
Vue.use(ElementUI)
// $service
Vue.use(AxiosPlugin)

document.title = `样本户之家 ${packageInfo.version}`

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
