import Qs from 'qs'
import Axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store'

const axiosConfig = {
  baseURL: process.env.NODE_ENV === 'production' ? '/' : '/api',
  timeout: 60000,
  withCredentials: true,
  responseType: 'json',
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  maxRedirects: 5,
  maxContentLength: 2000,
  paramsSerializer(params) {
    return Qs.stringify(params)
  },
}
export const service = Axios.create(axiosConfig)
// 请求拦截
service.interceptors.request.use(config => {
  if (!config.data) {
    config.data = {}
  }
  return config
})
// 响应拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.ret_code === 403) {
      Message({
        message: '登录过期，请重新登录',
        type: 'error',
        duration: 2000,
      })
      // 清空状态
      store.commit('user/RESET_STATE')
      // 记录登出路由
      store.commit('user/SET_NEXT', router.app.$route.path)
      // 跳转登录
      router.replace('/login')
      return
    }
    // 根据响应码
    if (res.ret_code !== 0 && res.ret_msg) {
      Message({
        message: res.ret_msg,
        type: 'error',
        duration: 5000,
      })
      return response
    }
    return response
  },
  error => {
    Message({
      message: '网络异常',
      type: 'error',
      duration: 2000,
    })
    return Promise.reject(error)
  }
)
export class AxiosPlugin {
  static install(Vue) {
    Vue.prototype.$service = service
  }
}
