<template>
  <div class="container">
    <!-- 轮播 -->
    <el-carousel class="carousel" height="300px">
      <el-carousel-item>
        <div class="carousel-img" @click="onClickCarousel" />
      </el-carousel-item>
      <el-carousel-item v-if="user.tel">
        <div class="carousel-img carousel-1" @click="onClickCarousel" />
      </el-carousel-item>
      <el-carousel-item v-if="user.tel">
        <div class="carousel-img carousel-2" @click="onClickCarousel" />
      </el-carousel-item>
    </el-carousel>
    <!-- 导航 -->
    <div class="nav">
      <div :class="{ 'nav-card': true, disabled: !user.tel }" @click="user.tel && $router.push('/my/cooperate-info')">
        <img :src="require('../../assets/images/home/nav1.png')" />
        <div class="nav-card-title">我的</div>
        <div class="nav-card-content">查询合作点值，兑换礼品历史订单，设置收货地址，请点击这里。</div>
      </div>
      <div class="nav-card" @click="$router.push('/redeem-gift')">
        <img :src="require('../../assets/images/home/nav2.png')" />
        <div class="nav-card-title">礼品兑换</div>
        <div class="nav-card-content">不收取任何费用，礼品种类丰富，不定期上新，更多精美礼品请前往APP。</div>
      </div>
      <div :class="{ 'nav-card': true, disabled: !user.tel }" @click="user.tel && $router.push('/questionnaire-list')">
        <img :src="require('../../assets/images/home/nav3.png')" />
        <div class="nav-card-title">问卷调查</div>
        <div class="nav-card-content">参与问卷调查邀请，获得更多点值奖励。</div>
      </div>
    </div>
    <!-- 模态框 -->
    <el-dialog :visible.sync="dialogVisible" width="732px">
      <div class="modal">
        <img class="modal-gift-img" :src="require('../../assets/images/home/gift.png')" />
        <el-divider class="modal-gift-content"><div>您有一份见面礼，请扫描二维码下载样本家APP领取</div></el-divider>
        <div class="modal-qrcode">
          <div>
            <img class="qr-img" :src="require('../../assets/images/qrcode/ios.jpg')" />
            <span>苹果手机下载</span>
          </div>
          <div>
            <img class="qr-img" :src="require('../../assets/images/qrcode/android.jpg')" />
            <span>Andriod下载</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  data() {
    return {
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    // 'user.info.is_bjwj'
    user: {
      handler(user) {
        if (user.tel && user.info.is_accept_privacy_statement && user.info.is_bjwj) {
          this.dialogVisible = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onClickCarousel() {
      // window.open(window.location.href)
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  .carousel {
    height: 300px;
    width: 100%;
    max-width: 100%;
    &-img {
      // cursor: pointer;
      height: 300px;
      width: 100%;
      max-width: 100%;
      background: top/ cover no-repeat url(https://public.ctsp.com.cn/banner/banner1.png);
    }
    &-1 {
      background: top/ cover no-repeat url(https://public.ctsp.com.cn/banner/banner2.png);
    }
    &-2 {
      background: top/ cover no-repeat url(https://public.ctsp.com.cn/banner/banner3.jpg);
    }
  }
  .nav {
    margin: 23px auto;
    max-width: 1200px;
    height: 320px;
    display: flex;
    justify-content: space-between;
    &-card {
      cursor: pointer;
      background-color: white;
      box-sizing: border-box;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      width: 330px;
      height: 100%;
      border: 2px;
      img {
        display: block;
        margin: 30px auto 0;
        background-color: transparent;
      }
      &-title {
        margin: 14px auto 0;
        font-size: 22px;
        text-align: center;
      }
      &:hover {
        border: 2px solid sandybrown;
        color: sandybrown;
      }
      &-content {
        line-height: 26px;
        font-size: 16px;
        width: 220px;
        margin: 14px auto 0;
        text-align: center;
        color: #666;
      }
    }
    .disabled {
      background-color: #f4f4f4;
      cursor: not-allowed;
      border: 0;
    }
  }
  .modal {
    &-gift-img {
      margin: -20px auto 0;
      display: block;
    }
    &-gift-content {
      div {
        font-size: 24px;
        white-space: nowrap;
      }
    }
    &-qrcode {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      margin-bottom: 50px;
      .qr-img {
        width: 127px;
        height: 127px;
        display: block;
      }
      span {
        margin-top: 10px;
        display: block;
        text-align: center;
        font-size: 16px;
      }
    }
  }
}
</style>
