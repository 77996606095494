import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import order from './modules/order'

import VuexPersistence from 'vuex-persist'
// getters
const getters = {
  user: state => state.user,
  order: state => state.order,
}

// 持久化
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    order,
  },
  getters,
  plugins: [vuexLocal.plugin],
})
