<template>
  <div class="header-container">
    <div class="header-container-content">
      <div class="header-container-content-left">
        <slot name="left" />
      </div>
      <div class="header-container-content-right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>
<style lang="less" scoped>
.header-container {
  display: flex;
  width: 100vw;
  max-width: 100%;
  height: 79px;
  justify-content: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  &-content {
    width: 100%;
    max-width: 1158px;
    padding: 0 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
</style>
