<template>
  <div class="container">
    <div class="image" />
    <div class="content">抱歉，兑换失败！</div>
    <div class="btn-group">
      <el-button round plain type="primary" @click="link">继续兑换</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'order-failed',
  methods: {
    link() {
      this.$router.push('/redeem-gift/gift-list')
      this.$message.info('兑换失败，点值自动退回。')
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  flex-flow: column;
  .image {
    margin-top: 104px;
    width: 181px;
    height: 118px;
    background: url('../../../assets/images/order_failed.png');
  }
  .content {
    margin-top: 37px;
    font-size: 24px;
    color: #333;
  }
  .btn-group {
    display: flex;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}
</style>
