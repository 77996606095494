import { service } from '@/utils/axios'
const defaultNext = '/'
const defaultState = () => {
  return { name: '', tel: '', info: null, next: '' }
}

const state = defaultState()
const mutations = {
  RESET_STATE: state => {
    Object.assign(state, defaultState())
  },
  SET_TEL: (state, tel) => {
    state.tel = tel
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_INFO: (state, info) => {
    state.info = info
  },
  SET_POINT: (state, point) => {
    if (state.info) {
      state.info.point = point
    }
  },
  SET_NEXT: (state, next) => {
    state.next = next
  },
  SET_HAS_PWD: state => {
    state.info.is_have_password = true
  },
  SET_PRIVACY: (state, val) => {
    state.info.is_accept_privacy_statement = val
  },
}
const actions = {
  // 账号密码登录
  async loginByPwd({ commit }, userInfo) {
    const url = '/app/login_by_pwd'
    const res = await service.post(url, {
      ...userInfo,
      from_web: true,
      Device_type: 'PC',
      Device_id: 0,
    })
    sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
    if (res.data.ret_code === 0) {
      if (res.data.data.name === 'youke') {
        commit('SET_NAME', '游客')
        commit('SET_INFO', res.data.data)
      } else {
        commit('SET_TEL', res.data.data.tel)
        commit('SET_NAME', res.data.data.name)
        commit('SET_INFO', res.data.data)
      }
      if (state.next === '/login') {
        return defaultNext
      }
      return state.next || defaultNext
    } else {
      return { err: res.data.ret_msg }
    }
  },
  // 验证码登录
  async loginByVerify({ commit }, userInfo) {
    const res = await service.post('/app/login_by_verify', {
      ...userInfo,
      from_web: true,
      Device_type: 'PC',
      Device_id: 0,
    })
    sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
    if (res.data.ret_code === 0) {
      commit('SET_TEL', res.data.data.tel)
      commit('SET_NAME', res.data.data.name)
      commit('SET_INFO', res.data.data)
      if (state.next === '/login') {
        return defaultNext
      }
      if (!res.data.data.is_have_password) {
        return '/first-set-password'
      }
      return state.next || defaultNext
    } else {
      return { err: res.data.ret_msg }
    }
  },
  async getPointDetail() {
    const res = await service.post('/app/get_point_log')
    return res
  },
  async acceptPrivacy({ commit }, isAccept) {
    const res = await service.post('/app/accept_privacy_statement', { is_accept: isAccept })
    if (res.data.ret_code === 0) {
      commit('SET_PRIVACY', isAccept)
    }
    return isAccept
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
