import { service } from '@/utils/axios'

const actions = {
  //获取订单列表
  async getOrderList() {
    const url = '/app/get_order_list'
    const res = await service.post(url)
    return res
  },
  //获取订单详情
  async getOrderDetail(_, id) {
    const url = '/app/get_order_list'
    const res = await service.post(url, { id: id })
    return res
  },
  //获取物流信息
  async getOrderTarck(_, orderId) {
    const url = '/app/jd_order_tarck'
    const res = await service.post(url, { id: orderId })
    return res
  },
  //获取地址列表
  async getAddrList(_, id) {
    const url = '/app/get_addr_list'
    const res = await service.post(url, { id: id })
    return res
  },
  //增加、修改收货地址
  async addOrUpdateAddr(_, addrInfo) {
    const url = '/app/add_addr'
    const res = await service.post(url, addrInfo)
    return res
  },
  //省列表
  async getProvince() {
    const url = '/app/getProvince'
    const res = await service.post(url)
    return res
  },
  //市列表
  async getCity(_, provinceId) {
    const url = '/app/getCity'
    const res = await service.post(url, { province_id: provinceId })
    return res
  },
  //县列表
  async getCounty(_, cityId) {
    const url = '/app/getCounty'
    const res = await service.post(url, { city_id: cityId })
    return res
  },
  //乡列表
  async getTown(_, countyId) {
    const url = '/app/getTown'
    const res = await service.post(url, { county_id: countyId })
    return res
  },
  //删除地址
  async delAddr(_, addrId) {
    const url = '/app/del_addr'
    const res = await service.post(url, { addr_id: addrId })
    return res
  },
}

export default {
  namespaced: true,
  actions,
}
